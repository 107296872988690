<template>
  <div>
    <div class="d-flex justify-start align-center">
      <div class="demo-space-x">
        <v-avatar
          color="primary"
          class="v-avatar-light-bg primary--text"
          size="35"
        >
          <v-icon
            dense
            color="primary"
          >
            {{ icons.mdiTownHall }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <span class="d-block text-caption black--text">Sekolah</span>
        <span
          class="d-block text-subtitle-1 font-weight-bold black--text"
          style="line-height: 1.5rem !important"
        >
          {{ schoolName }}
        </span>
      </div>
    </div>
    <div class="mt-8">

    </div>
    <div class="mt-4">
      <v-row>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
        >
          <v-card :loading="isLoadingCard">
            <div class="d-flex align-center justify-space-between pa-6">
              <h3 class="text-subtitle-1 black--text font-weight-bold">Absensi Siswa</h3>
              <div>
                <v-autocomplete
                  :label="dateNow"
                  outlined
                  :items="date"
                  item-text="name"
                  item-value="id"
                  dense
                  class="filter"
                  hide-details="none"
                  @change="handleFilter($event)"
                ></v-autocomplete>
              </div>

            </div>
            <v-card-text>
              <apex-chart-expense-ratio :count="count"></apex-chart-expense-ratio>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12">
          <v-row>
            <v-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              cols="12"
              v-for="card,index in cards"
              :key="index"
            >
              <v-card :loading="isLoadingCard">
                <div class="d-flex justify-md-space-around align-center pa-md-4 pa-6">
                  <div>
                    <v-avatar :color="card.color">
                      <v-container class="d-flex align-center">
                        <v-img
                          :src="card.icon"
                        ></v-img>
                      </v-container>
                    </v-avatar>
                  </div>
                  <div class="ps-md-0 ps-4">
                    <p class="text-subtitle-1 black--text">{{ card.title }}</p>
                    <p class="text-h5 font-weight-bold black--text">{{ card.total }}</p>
                    <router-link
                      :to="card.url"
                      class="text-decoration-none"
                    >
                      <a class="text-subtitle-2">Lihat Detail</a>
                    </router-link>
                  </div>

                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import iconSchool from '@/assets/icons/courthouse.svg'
import iconBook from '@/assets/icons/white/book.svg'
import iconDanger from '@/assets/icons/white/danger.svg'
import iconDevices from '@/assets/icons/white/devices.svg'
import iconDocument from '@/assets/icons/white/document.svg'
import iconMessage from '@/assets/icons/white/messages-2.svg'
import iconMusic from '@/assets/icons/white/music.svg'
import iconPlay from '@/assets/icons/white/play.svg'
import iconReceipt from '@/assets/icons/white/receipt-2.svg'
import iconUser from '@/assets/icons/white/user.svg'
import { mdiDotsVertical, mdiTownHall } from '@mdi/js'
import ApexChartExpenseRatio from './ApexChartExpenseRatio.vue'

export default {
  name: 'DashboardAdmin',
  components: {
    ApexChartExpenseRatio,
  },
  data() {
    return {
      count: [0, 0, 0, 0],
      icons: {
        iconSchool,
        iconUser,
        iconDanger,
        iconBook,
        iconDevices,
        iconMessage,
        iconMusic,
        iconPlay,
        iconReceipt,
        iconDocument,
        mdiTownHall,
        mdiDotsVertical,
      },
      date: [
        {
          id: `01-${new Date().getFullYear()}`,
          name: `January ${new Date().getFullYear()}`,
        },
        {
          id: `02-${new Date().getFullYear()}`,
          name: `February ${new Date().getFullYear()}`,
        },
        {
          id: `03-${new Date().getFullYear()}`,
          name: `March ${new Date().getFullYear()}`,
        },
        {
          id: `04-${new Date().getFullYear()}`,
          name: `April ${new Date().getFullYear()}`,
        },
        {
          id: `05-${new Date().getFullYear()}`,
          name: `May ${new Date().getFullYear()}`,
        },
        {
          id: `06-${new Date().getFullYear()}`,
          name: `June ${new Date().getFullYear()}`,
        },
        {
          id: `07-${new Date().getFullYear()}`,
          name: `July ${new Date().getFullYear()}`,
        },
        {
          id: `08-${new Date().getFullYear()}`,
          name: `August ${new Date().getFullYear()}`,
        },
        {
          id: `09-${new Date().getFullYear()}`,
          name: `September ${new Date().getFullYear()}`,
        },
        {
          id: `10-${new Date().getFullYear()}`,
          name: `October ${new Date().getFullYear()}`,
        },
        {
          id: `11-${new Date().getFullYear()}`,
          name: `November ${new Date().getFullYear()}`,
        },
        {
          id: `12-${new Date().getFullYear()}`,
          name: `December ${new Date().getFullYear()}`,
        },
      ],
      dataSchool: {},
      schoolName: '',
      paramsDate: '',
      dateNow: '',
      service: 'dashboardadmin',
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      schoolData: '',
      isLoadingCard: true,
      cards: [
        {
          title: 'Total Siswa',
          keyName: 'count_student',
          total: 0,
          icon: iconUser,
          url: 'student-headmaster',
          color: '#00913E',
        },
        {
          title: 'Total Guru',
          keyName: 'count_teacher',
          total: 0,
          icon: iconUser,
          url: 'functionary-headmaster',
          color: '#E66C2E',
        },
      ],
    }
  },
  beforeMount() {
    this.dateNow = `${this.monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`
    this.checkFirstParams()
  },
  mounted() {
    this.schoolName = JSON.parse(localStorage.getItem('user')).user.name
    this.getDataCount({ date: this.paramsDate })
    this.getSchoolData()
  },
  methods: {
    async handleFilter(id) {
      await this.getDataCount({ date: id })
    },
    checkFirstParams() {
      this.date.forEach(item => {
        if (item.name === this.dateNow) this.paramsDate = item.id
      })
    },
    getSchoolData() {
      this.schoolData = JSON.parse(localStorage.getItem('user')).user.details[0].data_school
    },
    async getDataCount(params) {
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
      }).then(
        ({ data }) => {
          this.count = Object.values(data.data.absen)
          Object.keys(data.data).forEach((key, index) => {
            this.cards.forEach(el => {
              if (el.keyName === key) {
                el.total = data.data[key]
              }
            })
          })
          // this.count = Object.values(this.dataSchool.absen)
        },
        err => console.error(err),
      )
      this.isLoadingCard = false
    },
  },
}
</script>

<style>
</style>
